import React from "react";
import { Rnd } from "react-rnd";

function Window({ title, onClose, children }) {
   
  return (
    <Rnd
      default={{
        x: 100,
        y: 100,
        width: 1,
        height: 1,
      }}
      style={{
      
        fontFamily: "'MS Sans Serif', Arial, sans-serif",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: "200px",
          left: "600px",
          width: "600px",
          
          background: "#C0C0C0",
          border: "2px solid #808080",
          boxShadow: "4px 4px 0px #000",
          zIndex: 1000,
          fontFamily: "'MS Sans Serif', Arial, sans-serif",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#000080",
            color: "#fff",
            padding: "5px",
            cursor: "move", // Allows the draggable behavior
          }}
        >
          <span>{title}</span>
          <button
            onClick={onClose}
            style={{
              background: "#C0C0C0",
              border: "1px solid #808080",
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            X
          </button>
        </div>
        
      {/* Window Content */}
      <div style={{ padding: "10px", background: "#FFF", color: "black" }}>
  {title === "CV" ? (
    <div>
      <iframe src="https://drmabuse19.github.io/" style={{width: "100%", height: "400px", border: 0, borderRadius: "4px", overflow: "hidden"}} title="priceless-forest-7zj9z" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    </div>
  ) : (
    null
  )}
</div>
{/* Window Content */}
<div style={{ padding: "10px", background: "#FFF", color: "black" }}>
  {title === "About Me" ? (
    <div>
      <h2 style={{ margin: "0 0 10px", fontFamily: "'MS Sans Serif', Arial, sans-serif" }}>
        About Me
      </h2>
      <p style={{ margin: "0 0 10px", fontFamily: "'MS Sans Serif', Arial, sans-serif" }}>
        Hello! I'm Magnus Coucheron, a tech enthusiast, developer, and sailor with a love for blending creativity 
        and logic. I spend my days building innovative projects, exploring simulation games, and tinkering with 
        automation technologies.
      </p>
      <h3 style={{ margin: "0 0 10px", fontFamily: "'MS Sans Serif', Arial, sans-serif" }}>
        🛠 What I Do
      </h3>
      <ul style={{ margin: "0 0 10px", fontFamily: "'MS Sans Serif', Arial, sans-serif", paddingLeft: "20px" }}>
        <li>💻 Crafting web applications using React and Node.js.</li>
        <li>🎮 Designing immersive simulation games in Unity and Unreal Engine.</li>
        <li>🔧 Automating workflows with Python, FastAPI, and cloud integrations.</li>
        <li>📊 Visualizing data with Power BI to extract meaningful insights.</li>
        <li>🛥 Innovating in marine tech, combining sailing with automation and green energy.</li>
      </ul>
      <h3 style={{ margin: "0 0 10px", fontFamily: "'MS Sans Serif', Arial, sans-serif" }}>
        🌟 Fun Facts About Me
      </h3>
      <ul style={{ margin: "0 0 10px", fontFamily: "'MS Sans Serif', Arial, sans-serif", paddingLeft: "20px" }}>
        
        <li>🎲 I’m working on a fish farm simulator and a shipping game (because why not mix business with fun?).</li>
        <li>📸 I used to freelance as a photographer and video producer!</li>
        <li>🏔 My favorite escape is hiking in the mountains or exploring the vast ocean.</li>
        <li>👾 I enjoy creating retro-style apps with a Windows 95 aesthetic for nostalgic vibes.</li>
      </ul>
      <h3 style={{ margin: "0 0 10px", fontFamily: "'MS Sans Serif', Arial, sans-serif" }}>
        🤝 Let’s Connect
      </h3>
      <p style={{ margin: "0 0 10px", fontFamily: "'MS Sans Serif', Arial, sans-serif" }}>
        If you’d like to chat about tech, games, or adventures on the high seas, 
        
        to send me a message!
      </p>
    </div>
  ) : null}
</div>




   {/* Window Content */}
   <div style={{ padding: "10px", background: "#FFF", color: "black" }}>
  {title === "Contact" ? (
   <div style={{ padding: "10px", background: "#FFF", color: "black" }}>
   <form>
     <div style={{ marginBottom: "10px" }}>
       <label>
         Name:
         <input
           type="text"
           name="name"
           style={{
             display: "block",
             width: "100%",
             margin: "5px 0",
             padding: "5px",
           }}
           required
         />
       </label>
     </div>
     <div style={{ marginBottom: "10px" }}>
       <label>
         Email:
         <input
           type="email"
           name="email"
           style={{
             display: "block",
             width: "100%",
             margin: "5px 0",
             padding: "5px",
           }}
           required
         />
       </label>
     </div>
     <div style={{ marginBottom: "10px" }}>
       <label>
         Message:
         <textarea
           name="message"
           style={{
             display: "block",
             width: "100%",
             height: "80px",
             margin: "5px 0",
             padding: "5px",
           }}
           required
         ></textarea>
       </label>
     </div>
     <button
       type="submit"
       style={{
         background: "#000080",
         color: "#FFF",
         border: "1px solid #808080",
         padding: "10px 15px",
         cursor: "pointer",
         fontSize: "14px",
         fontFamily: "'MS Sans Serif', Arial, sans-serif",
       }}
     >
       Send
     </button>
   </form>
 </div>
 
  ) : (
    null
  )}
</div>
      </div>
      </Rnd>
  );
}

export default Window;
