import React, { useState, useEffect } from "react";
import Window from "./Window";
import WifiIcon from '@mui/icons-material/Wifi';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import WifiOffIcon from '@mui/icons-material/WifiOff';
function Taskbar() {
  const [isStartMenuOpen, setStartMenuOpen] = useState(false);
  const [openWindows, setOpenWindows] = useState([]);
  const [currentTime, setCurrentTime] = useState("");
  const [volume, setVolume] = useState(true);
  const [wifi, setWifi] = useState(true);

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      const timeString = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      setCurrentTime(timeString);
    };

    updateClock(); // Set initial time
    const timer = setInterval(updateClock, 1000);

    return () => clearInterval(timer); // Cleanup the timer
  }, []);

  const toggleStartMenu = () => {
    setStartMenuOpen(!isStartMenuOpen);
  };

  const openWindow = (title) => {
    if (!openWindows.some((window) => window.title === title)) {
      setOpenWindows([...openWindows, { title }]);
    }
    setStartMenuOpen(false); // Close the Start menu after opening a window
  };

  const closeWindow = (title) => {
    setOpenWindows(openWindows.filter((window) => window.title !== title));
  };

  return (
    <div
      style={{
        height: 30,
        background: "#CCC",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 10,
        paddingRight: 10,
        position: "relative",
      }}
    >
      <button
        style={{
          background: "#b8b8b8",
          padding: "5px",
          cursor: "pointer",
        }}
        onClick={toggleStartMenu}
      >
        Start
      </button>

      {isStartMenuOpen && (
        <div
          style={{
            position: "absolute",
            bottom: "30px",
            left: "10px",
            width: "200px",
            background: "#C0C0C0",
            border: "2px solid #000",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
            padding: "10px",
          }}
        >
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              margin: 0,
            }}
          >
            <li
              style={{ padding: "5px", cursor: "pointer", color: "black" }}
              onClick={() => openWindow("About Me")}
            >
              About Me
            </li>
            <li
              style={{ padding: "5px", cursor: "pointer", color: "black" }}
              onClick={() => openWindow("CV")}
            >
              CV
            </li>
            <li
              style={{ padding: "5px", cursor: "pointer", color: "black" }}
              onClick={() => openWindow("Contact")}
            >
              Contact
            </li>
          </ul>
        </div>
      )}

      {openWindows.map((window, index) => (
        <Window
          key={index}
          title={window.title}
          onClose={() => closeWindow(window.title)}
        >
          <p>This is the content for {window.title}.</p>
        </Window>
      ))}

      {/* Clock Section */}
      <div
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          color: "#000",
          display: "flex",
          
          alignItems: "center",
        }}
      >
        {volume ?<VolumeUpIcon onClick={()=>setVolume(false)} /> :<VolumeOffIcon onClick={()=>setVolume(true)} />} 
        {wifi ?<WifiIcon onClick={()=>setWifi(false)} /> :<WifiOffIcon onClick={()=>setWifi(true)} />}
        <p style={{margin:"1em"}}>{currentTime}</p>
      </div>
    </div>
  );
}

export default Taskbar;
