import React, { useState, useEffect } from "react";
import Taskbar from "./components/Taskbar";
import Window from "./components/Window";
import wallpaper from "./assets/wallpaper.jpg";
import "./App.css";

function App() {
  const [openWindows, setOpenWindows] = useState([]);

  // Dynamically update the document title based on open windows
  useEffect(() => {
    if (openWindows.length === 0) {
      document.title = "6c6 portfolio";
    } else {
      document.title = `6c6 portfolio - ${openWindows.join(", ")} Open`;
    }
  }, [openWindows]);

  const handleCloseWindow = (title) => {
    setOpenWindows(openWindows.filter((window) => window !== title));
  };
   // Dynamically update the favicon based on open windows
   useEffect(() => {
    const link = document.querySelector("link[rel='icon']");
    if (link) {
      if (openWindows.length === 0) {
        link.href = "/lion.ico"; // Use your lion.png as the favicon
      } else {
        link.href = "/lion.ico"; // You can switch to another icon if needed
      }
    }
  }, [openWindows]);
  return (
    <div
      style={{
        backgroundImage: `url(${wallpaper})`,
        height: "100vh",
        width: "100vw",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ flex: 1, position: "relative" }}>
        {openWindows.map((windowTitle) => (
          <Window
            key={windowTitle}
            title={windowTitle}
            onClose={() => handleCloseWindow(windowTitle)}
          >
            <p>{`This is the content for ${windowTitle}.`}</p>
          </Window>
        ))}
      </div>
      <Taskbar />
    </div>
  );
}

export default App;
